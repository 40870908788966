<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
</style> 
<template>
  <vx-card title="Bank Statement">
  <div class="vx-row mb-12">
    <div class="vx-col w-5/6 ">
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-button
            type="line"
            icon-pack="feather"
            color="Red"
            icon="icon-arrow-left"
            v-on:click.stop="
              handleBack(tr)"
          />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Bank"
            name="Bank"
            v-model="bankStatement.BankName"
            placeholder=""
          />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Account Number"
            name="Bank"
            v-model="bankStatement.AccountNumber"
            placeholder=""
          />
        </div>
      </div>
      <div class="vx-row mb-2">
        <div class="vx-col w-1/2">
          <vs-input
            class="w-full input-readonly"
            label="Code"
            name="Code"
            v-model="bankStatement.Code"
            placeholder=""
          />
        </div>
      </div>
      <div>
        <div class="vx-row mb-6" style="width: 50%">
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Operating Unit / Territory</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <multiselect
            class="selectExample"
            v-model="operatingUnit"
            :options="optionOU"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="120"
            :limit="3"
            placeholder="Type to search"
            track-by="ID"
            label="Name"
          >
            <template slot="singleLabel" slot-scope="props">
              <span class="option__desc">
                <span class="option__title"
                  >({{ props.option.Code }}), ({{
                    props.option.OperatingUnitTerritory.Territory.code
                  }})
                  {{ props.option.OperatingUnitTerritory.Territory.name }}</span
                >
              </span>
            </template>

            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"
                  >({{ props.option.Code }}), ({{
                    props.option.OperatingUnitTerritory.Territory.code
                  }})
                  {{ props.option.OperatingUnitTerritory.Territory.name }}</span
                >
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      </div>
    </div>
    <div
      v-bind:class="[
        detail ? detailShow + ' vertical-divider' : '',
        detailHide,
      ]"
    >
    <data-table
      :responseData="responseData"
      :propsParams="params"
      :header="header"
      @reloadDataFromChild="reloadData"
    >
      <template slot="thead">
        <th width="7%">
        Action
        </th>
      </template>
      <template slot="tbody">
        <vs-tr :key="indextr" v-for="(tr, indextr) in responseData.bankStatementLines"  :class="tr.class">
          <vs-td class="whitespace-no-wrap">
              <template >
                <div class="mt-4 flex justify-between">
                <vx-tooltip text="Show" class="mr-4"  v-if="tr.CashBankID == 0 && tr.PaymentID == 0">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                    v-on:click.stop="
                      handleShow(tr)"
                  />
                </vx-tooltip>
                <template v-else>
                  reconciled
                </template>
                
                </div>
              </template>

            </vs-td>
          <vs-td>
            {{ tr.TypeTransaction }}
          </vs-td>
          <vs-td>
            {{tr.BranchCode}}
          </vs-td>
          <vs-td>
            {{tr.NameVa}}
          </vs-td>
          <vs-td>
            Value : {{formatPrice(tr.TransactionValue)}}<br>
            Reference Code : {{tr.ReferenceCode ? tr.ReferenceCode : '-'}}<br>
            No Giro : {{tr.NoGiro ? tr.NoGiro : '-'}}<br>
            Remark : {{tr.Note}}<br>
            Txn Date : {{dateFormat(tr.TxnDate)}}<br>
            <template v-if="tr.CashBankID != 0">
              Cash Bank Code : {{tr.CashBankCode}}
            </template>
            <template v-if="tr.PaymentID != 0">
              Payment Code : {{tr.PaymentCode}}
            </template>

            <!-- Reference Code : {{tr.ReferenceCode}}<br>
            Cash Bank Code : {{tr.CashBankCode}} -->
          </vs-td>
        </vs-tr>
      </template>
    </data-table>
    <!-- Approve Prompt -->
    
    </div>
      <!-- form -->
    <transition name="detail-fade">
      <div
        v-if="detail"
        v-bind:class="[
          detail ? detailShow + 'vx-col md:w-1/2 w-full  mb-base' : '',
          detailHide,
        ]"
      >
        <vs-row>
          <vs-col
            style="padding-bottom: 8px"
            vs-offset="8"
            vs-type="flex"
            vs-justify="rigth"
            vs-align="rigth"
            vs-w="4"
          >
            <vs-button
              class="ml-auto"
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
              >Close</vs-button
            >
          </vs-col>
          <vs-col
            
            vs-offset="0"
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="1"
          >
            <vs-button
              size="small"
              v-on:click="handleClose"
              color="grey"
              icon-pack="feather"
              icon="icon-x-square"
            ></vs-button>
          </vs-col>
          <detail-div @close="handleClose" :selected="selectedData" />
        </vs-row>
      </div>
    </transition>
  </div>
  </vx-card>
</template>
<script>
import moment from "moment"
import detail from "./detail.vue"
// import { dataPaymentCollection } from "../../../../../services/api/invoice";
export default {
  components: {
    "detail-div" : detail
  },
  props: {
    selected: Object,
    option: Object,
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "id",
      },
      header: [
        {
          text: "Type Transaction",
          value: "type_transaction",
          // width: '5%'
        },
        {
          text: "Branch Code",
          value: "branch_code",
        },
        {
          text: "Name VA",
          value: "name_va",
        },
        {
          text: "Informasi",
          sortable: false ,
        }
      ],
      optionOU: [],
      operatingUnit: {},
      methods:['','Cash','Giro','Cheque','Transfer'],
      responseData: {},
      detailShow: "vx-col md:w-1/2 w-full mb-base",
      detailHide: "core vx-col md:w-1/1 w-full mb-base",
      detail: false,
      notes: "",
      bankStatement: {},
      approveOrReject: false,
      approvalPrompt: false,
      rejectPrompt: false,
      selectedData: {},
      bankStatementID: null,
    };
  },
  computed: {
    
    // operatingUnit(){
    //   console.log(this.operatingUnit,"ou")
    // }
  },
  watch: {
    operatingUnit() {
      console.log(this.operatingUnit,"ou")
      this.reloadData(this.params)
      this.handleClose()
    },
  },
  mounted() {
    console.log(this.$route.params.id)
    this.bankStatementID = this.$route.params.id
    // this.reloadData(this.$route.params.id);
    this.findBankStatement(this.$route.params.id)
    this.getOptionOU()
    // this.getData();
    // this.page(1);
    // this.dataId = this.data[0].id
  },
  methods: {
    findBankStatement(id) {
      this.$http.get('/api/v1/bank-statement/data-table/' + id).then((r) => {
          if (r.code == 200) {
            this.bankStatement = r.data.bankStatement
          } else {
            this.$vs.notify({
              color: "danger",
              title: r.message,
              text: "error catch",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.handleBack()
          }
      }).catch((e) => {
        this.$vs.notify({
          color: "danger",
          title: "error catch",
          text: "error catch",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        this.handleBack()
      })
    
    },
    handleBack(){
      this.$router.push("/finance/bank-statement");
    },
    getOptionOU() {
      this.$http.get("/api/v1/master/operating-unit").then((resp) => {
        this.optionOU = resp.data.records;
        this.operatingUnit = resp.data.records[0];
      });
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    handleApprove(selected){
      this.selectedData.class = ""
      this.approveOrReject = 1
      selected.class =  "highlight"
      this.selectedData = selected
      this.rejectPrompt = false
      this.approvalPrompt = true
      console.log(this.selectedData)
    },
    handleReject(selected){
      this.selectedData.class = ""
      this.approveOrReject = 0
      selected.class =  "highlight"
      this.selectedData = selected
      this.approvalPrompt = false
      this.rejectPrompt = true
    },
    
    
    closeDetail() {
      this.activePrompt2 = false;
      this.detail = false;
      this.selectedData.class = ""
      this.selectedData = {
        ID: 0,
      };
      this.reloadData(this.params)
    },
    dataTable(params) {
      console.log(this.operatingUnit, 'dttb')
      return new Promise((resolve, reject) => {
        this.$http.get('/api/v1/bank-statement/line/data-table/' + this.bankStatementID,{
            params: {
                status: 0,
                search: params.search,
                length: params.length,
                page: params.page,
                order: params.sort,
                sort: params.order,
                territory_id: this.operatingUnit ? this.operatingUnit.OperatingUnitTerritory.TerritoryID : 0,
                operating_unit_id: this.operatingUnit ? this.operatingUnit.ID : 0,
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    handleShow(selected) {
      this.selectedData.class = ""
      selected.class =  "highlight"
      this.selectedData = selected
      this.detail = true
      
    },
    handleClose() {
      this.reloadData(this.params)
      this.selectedData.class = ""
      this.detail = false
      this.selectedData = {
        ID: 0,
      };
    },
    formatPrice(angka, prefix = "") {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.bankStatementLines;
          this.responseData = r.data;
          this.responseData.length = r.data.bankStatementLines.length;
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
};
</script>
 <style scoped>
.nonfixed {
  position: inherit;
  padding-left: 20px;
}
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transform-origin-y: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-right: 1px solid #7367f0;
  /* min-height: 800px; */
  /* height: 100%; */
  -webkit-mask-position-y: fixed;
  /* padding: 5px; */
}
.vs-con-table.stripe .tr-values:nth-child(2n) {
  background: beige;
}
.highlight > td {
  background-color: #d0cdf0;
}
.colored {
  border: 1px solid #7367f0;
  position: fixed;
  left: 4%;
  top: 40%;
  max-width: 45%;
  z-index: 999999999999;
  background: antiquewhite;
  background-color: antiquewhite;
}
.vs-dialog {
  max-width: 1000px;
}
</style>
