<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
.fullpage-div {
  height: 80vh;
  /* background-color:#FFFFFF; */
}
.div-scroll {
  /* background-color: lightblue; */
  /* max-width: 150px; */
  height: 80%;
  overflow:auto;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <div class="vx-col w-full">
        <vs-radio v-model="type" vs-value="cash-deposit" class="mr-4" vs-name="footer-type-hidden">Cash Deposit</vs-radio>
        <!-- <vs-radio v-model="type" vs-value="payment" class="mr-4" vs-name="footer-type-static">Transfer / Cheque</vs-radio> -->
          
    </div>
    <vs-popup title="" :active.sync="detail">
      <div class="vx-row mb-6">
        <div class="vx-col w-full" >
        
          <div class="fullpage-div">
            <div class="div-scroll">
              <template>
                <vs-card v-bind:key="indextr" v-for="(invoice,indextr) in invoices.CashBankManagementLine">
                <div slot="header">
                  <h6>
                    Customer : {{invoice.Payment.CustomerName}} ({{invoice.Payment.CustomerCode}}) <br>
                    Invoice : {{invoice.Payment.PaymentLine[0].InvoiceCode}}<br>
                    Number : {{invoice.PaymentNumber}} <br>
                    Method : {{invoice.PaymentMethod}} <br>
                    Value : {{priceFormat(invoice.PaymentValue)}} <br>
                  </h6>
                </div>
                <div>
                </div>
                </vs-card>
              </template>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>

    <!-- <vs-divider style="width:100%;margin-left:2%">Suggestion</vs-divider>
    <div v-if="suggestion.length < 1" class="vx-col w-full">
      <vs-alert color="danger">
        Not Found Suggestion
      </vs-alert>
    </div>
    <div v-else>
      <vs-card v-bind:key="indextr" v-for="(tr,indextr) in suggestion">
        <div slot="header">
          <h6>
            {{tr.Code}}
          </h6>
        </div>
        <div>
          <span>
            Method : {{methods[tr.Method]}}<br>
            Ref Code : {{tr.ReferenceCode}}<br>
            <template v-if="tr.GiroBankID!=0">
              Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
              {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
              {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
              {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
            </template>
          </span>
        </div>
        <div slot="footer">
          <vs-row vs-justify="flex-end">
            <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
          </vs-row>
        </div>
      </vs-card>
    </div> -->
    <vs-divider style="width:100%;margin-left:2%">Option</vs-divider>
    <div class="vx-col w-full">
      <card-data-table
      :responseData="responseData"
      :propsParams="params"
      @reloadDataFromChild="reloadData"
      >
      <template slot="tbody">
        <vs-card v-bind:key="indextr" v-for="(tr,indextr) in optionOther">
          <div slot="header">
            <h6>
              {{tr.Code}}
            </h6>
          </div>
          <div>
            <span v-if="type == 'cash-deposit'">
              Method : {{methods[tr.Method]}}<br>
              Ref Code : {{tr.ReferenceCode}}<br>
              Deposit Value : {{priceFormat(tr.DepositValue)}}<br>
              <!-- {{tr.CashBankManagementLine}} -->
              <template v-if="tr.GiroBankID!=0">
                Customer : {{ '(' + tr.PersonCode + ') ' + tr.PersonName}} <br>
                {{methods[tr.Method]}} Bank : {{tr.GiroBankName}}<br>
                {{methods[tr.Method]}} Number : {{tr.GiroNumber}}<br>
                {{methods[tr.Method]}} Date : {{dateFormat(tr.GiroDate)}}<br>
              </template>
               <vx-tooltip text="Detail Mapping" class="mr-4"  >
                  <vs-button
                  v-on:click="showDetail(tr)"
                    type="line"
                    icon-pack="feather"
                    icon="icon-eye"
                  />
                </vx-tooltip>
            </span>
            <span v-else>
              Method : {{tr.PaymentMethod}}<br>
              Ref Code : {{tr.PaymentReferenceCode}}<br>
              Payment Value : {{priceFormat(tr.Amount)}}<br>
              <template v-if="tr.GiroBankID!=0">
                Customer : {{ '(' + tr.CustomerCode + ') ' + tr.CustomerName}} <br>
                Bank : {{tr.PaymentName}} ({{tr.PaymentAccountOwner}} - {{tr.PaymentAccountNumber}})<br>
              </template>
            </span>
          </div>
          <div slot="footer">
            <vs-row vs-justify="flex-end">
              <vs-button type="gradient" color="danger" v-on:click="handleReconcile(tr)">reconcile</vs-button>
            </vs-row>
          </div>
        </vs-card>
      </template>
      </card-data-table>
      <!-- <vs-button v-if="other.length < optionOther.length " color="primary" type="filled" v-on:click="loadMore" >Load More </vs-button> -->
      
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment"

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        responseData: {},
        detail: false,
        params: {
          search: "",
          length: 2,
          page: 1,
          order: "desc",
          sort: "id",
        },
        methods: ['','Cash', 'Giro', 'Check','Transfer'],
        suggestion:[],
        type:'cash-deposit',
        suggestID:[],
        other:[],
        optionOther: [],
        invoices:[],
      };
    },
    showDetail(data) {
      this.invoices = data
      this.detail = 1
    },
    formatPrice(angka) {
      return angka.toString().replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getSuggestion(){
      const params = {
        search : this.selected.ReferenceCode
      };
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.suggestion = r.data.cashBank;
          this.suggestID = []
          this.suggestion.map(r => {
            this.suggestID.push(r.ID)
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    handleReconcile(tr) {
      console.log(tr)
      this.$vs.loading();
      let params = {}
      if (this.type == 'cash-deposit') {
        params = {
          "cash_bank_id" : tr.ID,
          "bank_statement_id" : this.selected.ID,
        }
      } else {
        params = {
          "payment_id" : tr.ID,
          "bank_statement_id" : this.selected.ID,
        }
      }
      
      
      this.$http.post('/api/v1/bank-statement/reconcile-manual',params)
      .then((resp) => {
        this.$vs.loading.close();
        if (resp.code == 200) {
          this.handleClose();
          
          this.$vs.notify({
            color: "success",
            title: "Success",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: resp.message,
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          
        }
      }).catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e)
      })

    },
    loadMore() {
      // this.$vs.loading();
      this.optionOther.map((r,i) => {
        if (i >= this.other.length && i <= (parseInt(this.other.length) +parseInt(10))   ) {
          if (!this.suggestID.includes(r.ID)) {
            this.other.push(r)
          }
        }
      })
    },
    getOther(){
      const params = {
        search : ""
      };
      console.log(params);
      this.$vs.loading();
      this.other = []
      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.optionOther = r.data.cashBank;
          this.optionOther.map((r,i) => {
            if (i < 2) {
              if (!this.suggestID.includes(r.ID)) {
                this.other.push(r)
              }
            }
          })
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    reloadData(params) {
      this.params = params;
      console.log(params);
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          if (this.type == 'cash-deposit') {
            this.optionOther = r.data.cashBank;
            this.responseData.length = r.data.cashBank.length;
          } else {
            this.optionOther = r.data.payment;
            this.responseData.length = r.data.payment.length;
          }
          this.responseData = r.data;
          
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        let path = ''
        let status = ''
        let sort = "id"
        let order = "desc"
        if (this.type == 'cash-deposit') {
          path = '/cash-bank/data-table'
          status = 3
          order = "id",
          sort = "desc"
        } else {
          path = '/payment/data-table'
          status = 2
          sort = "id",
          order = "desc"
        }
        this.$http.get('/api/v1' + path ,{
            params: {
                type: 1,
                status: status,
                search: params.search,
                length: 2,
                page: params.page,
                order: order,
                sort: sort,
                territory_id: this.selected.TerritoryID,
                is_reconcile_bank_statement: true,
            }
        }).then((r) => {
          resolve(r)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    
  },
  mounted() {
    console.log(this.selected)
    console.log("mounted")
    if (this.selected.ReferenceCode != "") {
      this.getSuggestion()
    } 
    // this.getOther()
  },
  computed: {},
  watch: {
    "create.method"() {
      
    },
    "type"() {
      this.reloadData(this.params)
    },
    "selected.ID"() {
      this.$nextTick(() => {
        // if (this.selected.ReferenceCode != "") {
        //   this.getSuggestion()
        // } 
        this.reloadData(this.params)
      
        console.log(this.selected)
      })
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>